import { AxiosError } from 'axios';
import {
  STATUS_CODES,
  DEFAULT_MSG_BY_STATUS_CODE,
  DEFAULT_PARSERS_BY_STATUS_CODE,
  DEFAULT_MSG,
  I18N_ERROR_KEYS_BY_STATUS_CODES,
} from './consts';
import { buildResponse, getPreparedError, parseOauthValidationError } from './lib';

export const parse = (
  error: AxiosError,
  customParser?: (error: AxiosError) => string | undefined,
  options: {
    errorMessageMapper?: (message: string) => string;
    description?: string;
    isCreateExtendedDefaultErrorMessage?: boolean;
    defaultErrorMessage?: string;
    msgByStatusCode?: Record<number, string>;
    parsersByStatusCode?: Record<number, (error: AxiosError) => string | undefined>;
    $t?: (msg: string) => string ;
  } = {},
): {
  statusCode?: number;
  msg: string
} => {
  const {
    errorMessageMapper = (msg) => msg,
    description = '',
    isCreateExtendedDefaultErrorMessage = false,
    msgByStatusCode = I18N_ERROR_KEYS_BY_STATUS_CODES.BY_STATUS_CODE,
    parsersByStatusCode = {},
    $t,
  } = options;

  const MSG_BY_STATUS_CODE: Record<number, string> = { ...DEFAULT_MSG_BY_STATUS_CODE, ...msgByStatusCode };
  const PARSERS_BY_STATUS_CODE = { ...DEFAULT_PARSERS_BY_STATUS_CODE, ...parsersByStatusCode };

  const statusCode = error.response?.status;

  const handleMsg = (msg: unknown): unknown => {
    const i18nExists = $t && typeof $t === 'function';
    return msg && i18nExists ? $t(msg as string) : msg;
  };

  const getErrorMessage = (): string => {
    const statusCodeMsg = statusCode ? ` Code: ${statusCode}. ` : '';

    const preparedError = getPreparedError(error);

    const parser = statusCode && PARSERS_BY_STATUS_CODE[statusCode];

    let msg: unknown = (customParser && customParser(error))
      || (parser && parser(error))
      || (typeof statusCode === 'number' ? MSG_BY_STATUS_CODE[statusCode] : undefined);

    if (msg === undefined) {
      msg = isCreateExtendedDefaultErrorMessage
        ? [preparedError, statusCodeMsg, description].filter(Boolean).join(' ')
        : DEFAULT_MSG;
    }

    return <string>handleMsg(msg);
  };

  if (!statusCode) {
    return buildResponse({ msg: getErrorMessage() }, errorMessageMapper);
  }

  const isOauthError = statusCode === STATUS_CODES.VALIDATION && error.response?.data?.errorDescription;
  if (isOauthError) {
    return buildResponse({ statusCode, msg: parseOauthValidationError(error) }, errorMessageMapper);
  }

  return buildResponse({ statusCode, msg: getErrorMessage() }, errorMessageMapper);
};
