<script setup lang="ts">
import { computed, ComputedRef, useSlots } from 'vue';
import { AIcon } from '../../a-icon';
import { ASpinnerDot } from '../../a-spinner-dot';
import { AText } from '../../a-text';
import {
  DESIGN_TYPES,
  COMPOSITION_TYPES,
  SIZE_HEIGHT_CLASSES,
  SIZE_PADDING_CLASSES,
  SIZE_FONT_CLASSES,
  SIZE_ICON_CLASSES,
  SIZE_GAP_CLASSES, SIZE_WIDTH_CLASSES, BORDER_RADIUS_CLASSES,
} from '../const';
import { SIZES } from '../const/sizes';
import { Props } from '../types';

const props = withDefaults(defineProps<Props>(), {
  size: SIZES.M,
  type: DESIGN_TYPES.FILL,
  loading: false,
  disabled: false,
  tag: 'button',
});

const slots = useSlots();

const hasSlotContent: ComputedRef<boolean> = computed(() => !!slots.default?.({}).length);

const composition: ComputedRef<typeof COMPOSITION_TYPES[keyof typeof COMPOSITION_TYPES]> = computed(() => {
  if ((props.leftIcon || props.rightIcon || props.loading) && !hasSlotContent.value) {
    return COMPOSITION_TYPES.ONLY_ICON;
  }
  if (props.leftIcon) {
    return COMPOSITION_TYPES.LEFT_ICON;
  }
  if (props.rightIcon) {
    return COMPOSITION_TYPES.RIGHT_ICON;
  }
  return COMPOSITION_TYPES.TEXT;
});

const classes = computed(() => ([
  SIZE_HEIGHT_CLASSES[props.size],
  composition.value === 'icon' ? SIZE_WIDTH_CLASSES[props.size] : SIZE_PADDING_CLASSES[composition.value][props.size],
  SIZE_GAP_CLASSES[props.size],
  BORDER_RADIUS_CLASSES[props.size],
  {
    'before:border before:border-transparent-gray-10': ([DESIGN_TYPES.FILL, DESIGN_TYPES.OUTLINE] as Array<typeof props.type>).includes(props.type),
    'after:bg-accent-branded text-absolute-white': props.type === DESIGN_TYPES.FILL,
    'text-text-primary': ([DESIGN_TYPES.OUTLINE, DESIGN_TYPES.GHOST] as Array<typeof props.type>).includes(props.type),
    'text-accent-link': props.type === DESIGN_TYPES.LINK,
    'bg-transparent-full': ([DESIGN_TYPES.OUTLINE, DESIGN_TYPES.GHOST, DESIGN_TYPES.LINK] as Array<typeof props.type>).includes(props.type),
    'border-stroke-secondary': props.type === DESIGN_TYPES.OUTLINE,
    'opacity-80': props.loading,
    'opacity-50': props.disabled,
    'flex-row-reverse': props.rightIcon,
  },
]));

const fontClasses = computed(() => ([
  SIZE_FONT_CLASSES[props.size],
]));

const iconClasses = computed(() => ([
  SIZE_ICON_CLASSES[props.size],
]));

const spinnerTheme = computed(() => (props.type === DESIGN_TYPES.FILL ? 'light' : 'dark'));

const htmlTag = computed(() => props.tag);
</script>

<template>
  <htmlTag
    class="apply-hover-focus after:bg-transparent relative inline-flex
      items-center justify-center overflow-hidden ring-inset
      *:z-10 before:absolute before:inset-0 before:z-10
      before:content-['']
      after:absolute after:inset-0 after:z-0
      after:content-[''] disabled:pointer-events-none"
    :class="classes"
    :disabled="disabled"
  >
    <a-spinner-dot
      v-if="loading"
      :size="size"
      :theme="spinnerTheme"
    />
    <a-icon
      v-else-if="props.leftIcon || props.rightIcon"
      :class="iconClasses"
      :name="props.leftIcon! || props.rightIcon!"
    />
    <a-text
      v-if="hasSlotContent"
      weight="semibold"
      :class="fontClasses"
      class="whitespace-nowrap"
    >
      <slot />
    </a-text>
  </htmlTag>
</template>
