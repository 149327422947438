import { AxiosError } from 'axios';
import { uiNotifier, stringLib, arrayLib } from '@fsd/shared/lib';
import { DEFAULT_MSG, MAX_ERROR_LENGTH } from '@fsd/shared/lib/http-error-parser/consts';

const { isArray } = arrayLib;

export const isAxiosError = (error: unknown): error is AxiosError => (error as AxiosError)?.isAxiosError === true;

export const showErrorMsg = (
  msg: unknown,
  multipleNotify?: boolean,
): void => {
  if (multipleNotify && isArray(msg)) {
    msg.forEach((message) => uiNotifier.error(message));
  } else if (typeof msg === 'object' && !isArray(msg) && msg !== null) {
    const msgObj = msg as Record<string, string[]>;

    Object.entries(msgObj).forEach(([, field]) => {
      if (isArray(field)) {
        field.forEach((item) => uiNotifier.error(stringLib.capitalize(item)));
      }
    });
  } else {
    uiNotifier.error(String(msg));
  }
};

export const getErrorMsg = (
  { error, description }: { error: unknown; description?: string },
  showAllDefaultErrorMessageExtended: boolean,
): string => {
  let preparedError: string;

  if (error instanceof Error && typeof error.message === 'string') {
    preparedError = error.message;
  } else if (typeof error === 'object' && error !== null) {
    preparedError = JSON.stringify(error);
  } else {
    preparedError = String(error);
  }

  preparedError = preparedError.slice(0, MAX_ERROR_LENGTH);

  return showAllDefaultErrorMessageExtended
    ? `${preparedError} ${description || ''}`
    : DEFAULT_MSG;
};

export const isErrorFromAbortController = (
  error: unknown,
): boolean => typeof error === 'object' && error !== null && 'message' in error && error.message === 'canceled';
