import {
  GETTER_IS_LOADING,
  GETTER_ACCOUNTS,
  GETTER_SELECTED_ACCOUNT,
  GETTER_PAYMENT_METHODS,
  GETTER_SELECTED_PAYMENT_METHOD,
  GETTER_RECOMMENDED_PAYMENT_METHODS,
  GETTER_IS_LOADING_CREATE_PAYMENT_REQUEST,
  GETTER_SERVER_ERROR,
} from './types';

export default {
  [GETTER_IS_LOADING]: ({ isLoading }) => isLoading,
  [GETTER_IS_LOADING_CREATE_PAYMENT_REQUEST]: ({ isLoadingCreatePaymentRequest }) => isLoadingCreatePaymentRequest,
  [GETTER_ACCOUNTS]: ({ accounts }) => accounts,
  [GETTER_SELECTED_ACCOUNT]: ({ selectedAccount }) => selectedAccount,
  [GETTER_PAYMENT_METHODS]: ({ paymentMethods }) => (payload) => paymentMethods.filter(
    (paymentMethod) => {
      const { accountCurrency } = payload;
      return paymentMethod.currency === accountCurrency;
    }),
  [GETTER_SELECTED_PAYMENT_METHOD]: ({ selectedPaymentMethod }) => selectedPaymentMethod,
  [GETTER_RECOMMENDED_PAYMENT_METHODS]: (_, getters) => (payload) => {
    const paymentMethods = getters[GETTER_PAYMENT_METHODS](payload);

    return paymentMethods.filter((paymentMethod) => {
      if (!paymentMethod.recommendedForCountry) {
        return false;
      }

      const { country } = payload;
      const recommendedForCountryArray = paymentMethod.recommendedForCountry.split(', ');

      return recommendedForCountryArray.includes(country.toLowerCase());
    });
  },
  [GETTER_SERVER_ERROR]: ({ serverError }) => serverError,
};
