import { Action } from '@amarkets/atlas/esm/lib/action';
import { isFunction } from '@amarkets/atlas/esm/lib/is-function';
import {
  MUTATION_SET_TRADING_ACCOUNTS_LOADING,
  MUTATION_SET_TRADING_ACCOUNTS,
} from '../types';
import { TradingAccount } from '../entities/trading-account';
import { TradingAccountsMapper } from '../mappers/trading-accounts-mapper';
import { useThrottle } from '@/lib/helpers';

export class FetchTradingAccounts extends Action {
  _api;
  _errorHandler;
  _throttledGetTradingAccounts;

  constructor({ accountApi, errorHandler }) {
    super();
    this._api = accountApi;
    this._errorHandler = errorHandler;

    this._throttledGetTradingAccounts = useThrottle(
      this._api.getTradingAccounts.bind(this._api),
      100,
    );
  }

  async execute({ commit }, { onSuccess } = {}) {
    await FetchTradingAccounts._withLoading(async () => {
      try {
        const tradingAccountsMapper = new TradingAccountsMapper((opts) => new TradingAccount(opts));

        const tradingAccounts = await this._throttledGetTradingAccounts(
          (data) => tradingAccountsMapper.mapTradingAccount(data),
        );
        const sortedTradingAccounts = [...tradingAccounts].sort((firstTradingAccount, secondTradingAccount) => (
          secondTradingAccount.equityInUsd - firstTradingAccount.equityInUsd
        ));

        commit(MUTATION_SET_TRADING_ACCOUNTS, sortedTradingAccounts);

        if (onSuccess && typeof isFunction(onSuccess)) onSuccess();
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchTradingAccounts');
      }
    }, commit, MUTATION_SET_TRADING_ACCOUNTS_LOADING);
  }
}
