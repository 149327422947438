import { AxiosInstance } from 'axios';
import { getAxiosInstance } from './lib';
import type { HttpClientConfig, CustomAxiosRequestConfig } from './types';

export const useHttpClient = (config?: HttpClientConfig, useSingleton = true) => {
  const axiosInstance: AxiosInstance = getAxiosInstance(config, useSingleton);

  const get = async <T>(url: string, options?: CustomAxiosRequestConfig): Promise<T> => {
    const response = await axiosInstance.get<T>(url, options);
    return response.data;
  };

  const post = async <T>(url: string, data?: unknown, options?: CustomAxiosRequestConfig): Promise<T> => {
    const response = await axiosInstance.post<T>(url, data, options);
    return response.data;
  };

  const put = async <T>(url: string, data?: unknown, options?: CustomAxiosRequestConfig): Promise<T> => {
    const response = await axiosInstance.put<T>(url, data, options);
    return response.data;
  };

  const patch = async <T>(url: string, data?: unknown, options?: CustomAxiosRequestConfig): Promise<T> => {
    const response = await axiosInstance.patch<T>(url, data, options);
    return response.data;
  };

  const del = async <T>(url: string, options?: CustomAxiosRequestConfig): Promise<T> => {
    const response = await axiosInstance.delete<T>(url, options);
    return response.data;
  };

  return {
    get,
    post,
    put,
    patch,
    delete: del,
  };
};
