import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useProfileApi } from '@fsd/shared/api';
import { mappersLib } from '../lib';
import { ProfileWithRole } from '../types';

export const useUserStore = defineStore('user', () => {
  const profile = ref<ProfileWithRole>();

  const { getProfile } = useProfileApi();

  const fetchProfile = async () => {
    const data = await getProfile();
    profile.value = { ...profile.value, ...mappersLib.profileMapper(data) } as ProfileWithRole;
  };

  return {
    profile,
    fetchProfile,
  };
});
