import { useUserRolesStore } from '@fsd/entities/user-roles';
import FacadeGlobal from '@/plugins/store/facades/facade-global';

export const setUserRoles = ({ userRoles, store }) => {
  const facadeGlobal = new FacadeGlobal(store);
  facadeGlobal.setUserRoles(userRoles);

  const userRolesStore = useUserRolesStore();
  userRolesStore.userRoles = userRoles;
};
