export const DOCUMENT_STATUSES = {
  NONE: 'none',
  REVIEW: 'review',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  REVOKED: 'revoked',
} as const;

interface Document {
  comment: string | null;
  status: typeof DOCUMENT_STATUSES[keyof typeof DOCUMENT_STATUSES];
  uid: string;
}

interface VerificationState {
  _status: string;
  _verifiedStatus: boolean;
  isDisabled: boolean;
  isBlocked: boolean;
  isVerified: boolean;
  isNotVerified: boolean;
}

export interface UserIdentify {
  customerAgreementRequired: boolean;
  documents: Document[];
  email: string;
  phoneNumberVerified: boolean;
  emailVerified: boolean;
  firstName: string;
  fullName: string;
  lastName: string;
  phoneNumber: string;
  userUid: string;
  verificationState: {
    email: VerificationState;
    phone: VerificationState;
  };
}

export type VerificationType = 'full' | 'simple'

export interface RouteMetaVerification {
  verification?: VerificationType;
  verificationModalText?: string;
}
