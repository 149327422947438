import { RouteRecordRaw } from 'vue-router';
import { SERVICES_ROUTES } from '@fsd/shared/constants/routes';

export const ExpertsRoutes: RouteRecordRaw[] = [
  {
    path: SERVICES_ROUTES.EXPERTS.PATH,
    component: () => import('./page-experts.vue'),
    children: [
      {
        name: SERVICES_ROUTES.EXPERTS.NAME,
        path: SERVICES_ROUTES.EXPERTS.PATH,
        component: () => import('./page-experts-table.vue'),
      },
      {
        name: SERVICES_ROUTES.EXPERTS_SINGLE.NAME,
        path: SERVICES_ROUTES.EXPERTS_SINGLE.PATH,
        component: () => import('./page-experts-single.vue'),
        props: true,
      },
    ],
    meta: {
      title: 'pageExperts.title',
    },
  },
];
