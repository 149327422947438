import type { Route } from './types';

export const COPY_TRADING_ROUTES: Record<string, Route> = {
  COPY_TRADING: {
    PATH: '/copy-trading',
    NAME: 'copy-trading',
  },
  INVESTOR: {
    PATH: 'investor',
    NAME: 'copy-trading-investor',
  },
  TRADER: {
    PATH: 'trader',
    NAME: 'copy-trading-trader',
  },
};
