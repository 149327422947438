import { RouteRecordRaw } from 'vue-router';
import { ROUTES } from '@/constants';

export const CashbackRoutes: RouteRecordRaw[] = [
  {
    name: ROUTES.CASHBACK.NAME,
    path: ROUTES.CASHBACK.PATH,
    component: () => import('./page-cashback.vue'),
    meta: {
      title: 'pageCashback.title',
    },
  },
];
