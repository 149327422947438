import { dateLib } from '@/fsd/shared/lib';
import { useHttpClient } from '../../http/index';
import { GetOperationsHistoryResponse } from './types';

const { get, delete: cancel } = useHttpClient();

export const useOperationsHistoryApi = () => {
  const getOperationsHistory = async (number: number, startDate: string = '2020-01-01', size: number = 20) => {
    // https://wiki.prod.amarkets.dev/doc/istoriya-operacij-K0ez8CMEoL#h-logika-razdela
    const { data, pagination } = await get<GetOperationsHistoryResponse>(`/v1/office/financial_operations?start_date=${startDate}&number=${number}&size=${size}`);

    return {
      data: data.map((item) => ({
        ...item.attributes,
        createdAt: dateLib.formatDate(new Date(item.attributes.createdAt), 'DD.MM.YYYY, HH:mm'),
        createdAtRaw: new Date(item.attributes.createdAt),
        amountRaw: parseFloat(item.attributes.amount.replace(/[^\d.-]/g, '')),
        amountWithCommissionRaw: parseFloat(item.attributes.amountWithCommission.replace(/[^\d.-]/g, '')),
        commissionRaw: parseFloat(item.attributes.commission.replace(/[^\d.-]/g, '')),
      })),
      pagination,
    };
  };

  const deleteOperation = async (id: string) => {
    await cancel(`/v1/office/withdrawals/${id}`);
  };

  return {
    getOperationsHistory,
    deleteOperation,
  };
};
