import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { USER_ROLES } from '../types';

export const useUserRolesStore = defineStore('user-roles', () => {
  const userRoles = ref<typeof USER_ROLES[keyof typeof USER_ROLES][]>([]);

  return {
    userRoles,
  };
});
