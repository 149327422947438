import { RouteRecordRaw } from 'vue-router';
import { PROFILE_ROUTES } from '@fsd/shared/constants/routes';

export const MyPartnerRoutes: RouteRecordRaw[] = [
  {
    name: PROFILE_ROUTES.MY_PARTNER.NAME,
    path: PROFILE_ROUTES.MY_PARTNER.PATH,
    component: () => import('./page-my-partner.vue'),
    meta: {
      title: 'pageTitle.myPartner',
    },
  },
];
