import { computed, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { languageStorage, loadLocaleMessages } from '@amarkets/core-vue';
import { createTranslationsByLocale } from '@/lib/utils';
import { useDisplayProvide } from './use-display-provide';

const translationsByLocales = createTranslationsByLocale();
const firstInitialTime = ref(true);

export function useLanguage() {
  const { locale, t, setLocaleMessage } = useI18n();
  const { isRtl } = useDisplayProvide();

  const changeLocale = async (_locale) => {
    await loadLocaleMessages(_locale, translationsByLocales, setLocaleMessage);

    locale.value = _locale;
    languageStorage.saveLocale(_locale);
  };

  watch(locale, async (_locale) => {
    if (firstInitialTime.value) {
      firstInitialTime.value = false;
      await loadLocaleMessages(_locale, translationsByLocales, setLocaleMessage);
    }
  }, { immediate: true });

  return {
    currentLocale: computed(() => locale.value),
    changeLocale,
    isRtl,
    $t: t,
  };
}
