<template>
  <v-main class="layout-init text-center align-center">
    <svg
      class="logo mt-1 mt-sm-8 mt-md-10 mt-lg-16"
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 164 164"
      fill="none"
    >
      <path
        d="M57.194 5.47072L0.127038 161.326C-0.361462 162.541
        0.615912 164 2.08215 164H15.4018C17.9679 164 20.2898
        162.42 21.1454 159.988L69.1692 28.3262C69.9023 26.5026
        72.4683 26.5026 73.0793 28.3262L97.1527 94.2181H57.6824C55.1163
        94.2181 52.7945 95.7985 51.9393 98.2298L46.9292
        111.967C46.4403 113.183 47.418 114.642 48.8843
        114.642H104.729L107.784 122.787H47.2956C44.7295 122.787
        42.4076 124.368 41.5524 126.799L36.5423 140.537C36.0535 141.752
        37.0312 143.212 38.4974 143.212H115.36L121.433 159.98C122.289
        162.412 124.57 163.992 127.136 163.992L152.597 164C154.064 164
        155.041 162.663 154.552 161.326L147.987 143.333H161.918C163.384
        143.333 164.361 141.996 163.873 140.658L158.863 126.921C158.007
        124.49 155.685 122.909 153.119 122.909H140.533L137.478
        114.764H151.409C152.875 114.764 153.852 113.426 153.364
        112.089L148.354 98.3514C147.498 95.9201 145.176 94.3397
        142.61 94.3397H130.024L97.519 5.47072C96.2971 2.18829 93.12 0
        89.5761 0H65.1365C61.593 0 58.4159 2.18829 57.194 5.47072Z"
        fill="url(#paint0_linear_1_7)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_7"
          x1="81.9816"
          y1="6.89771"
          x2="81.9816"
          y2="172.608"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F39200" />
          <stop
            offset="1"
            stop-color="#F07E00"
          />
        </linearGradient>
      </defs>
    </svg>

    <div class="d-flex flex-column align-center twp">
      <layout-init-loading v-if="isLoadingVisible" />
      <layout-init-error v-if="isErrorVisible" />
    </div>
  </v-main>
</template>

<script>
import LayoutInitError from './layout-init-error.vue';
import LayoutInitLoading from './layout-init-loading.vue';

export default {
  name: 'layout-init',
  components: {
    LayoutInitError,
    LayoutInitLoading,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isErrorVisible() {
      return (this.isLoading && this.isError) || this.isError;
    },
    isLoadingVisible() {
      return !this.isErrorVisible && this.isLoading;
    },
  },
};
</script>

<style lang="scss">
  @import 'layout-init';
</style>
