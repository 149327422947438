import { useLoading } from '@fsd/shared/lib';
import { useHttpClient } from '../../http/index';
import type { BannersResponse } from './types';

const { get } = useHttpClient();

export const useBannersApi = () => {
  const { isLoading, withLoading } = useLoading();

  const fetchBanners = async () => withLoading(async () => {
    const { data } = await get<BannersResponse>('/v1/usedator/banners');

    return {
      data,
    };
  });

  return {
    fetchBanners,
    isLoading,
  };
};
