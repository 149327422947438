import { RouteRecordRaw } from 'vue-router';
import { SERVICES_ROUTES } from '@fsd/shared/constants/routes';

export const VpsRoute: RouteRecordRaw[] = [
  {
    name: SERVICES_ROUTES.VPS.NAME,
    path: SERVICES_ROUTES.VPS.PATH,
    component: () => import('./page-vps.vue'),
    meta: {
      title: 'menu.link.vpsServers',
      // title: 'pageTitle.vps',
    },
  },
];
