import { useLoading } from '@fsd/shared/lib';
import { useHttpClient } from '../../http/index';
import type { RatesResponse } from './types';

const { get } = useHttpClient();

export const useRatesApi = () => {
  const { isLoading, withLoading } = useLoading();

  const getRate = async (base: string, target: string) => withLoading(async () => {
    const { data } = await get<RatesResponse>(`/v1/exchange/rates/${base}${target}/latest`);

    return data;
  });

  return {
    getRate,
    isLoading,
  };
};
