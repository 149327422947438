import { Action } from '@amarkets/atlas/esm/lib/action';
import { AgoraUserProfile } from '../entities/agora-user-profile';
import { AgoraWallet } from '../entities/agora-wallet';
import { AgoraUserProfileMapper } from '../mappers/agora-user-profile-mapper';
import { MUTATION_SET_AGORA_USER_PROFILE, MUTATION_SET_AGORA_USER_PROFILE_LOADING } from '../types';

export class FetchAgoraUserProfile extends Action {
  _api;
  _errorHandler;

  constructor({ agoraApi, errorHandler }) {
    super();

    this._api = agoraApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    await FetchAgoraUserProfile._withLoading(async () => {
      try {
        const userProfileMapper = new AgoraUserProfileMapper(
          (opts) => new AgoraWallet(opts),
          (opts) => new AgoraUserProfile(opts));

        const userProfile = await this._api.getUserProfile(
          (data) => userProfileMapper.mapAgoraUserProfile(data),
        );

        commit(MUTATION_SET_AGORA_USER_PROFILE, userProfile);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchAgoraUserProfile');
      }
    }, commit, MUTATION_SET_AGORA_USER_PROFILE_LOADING);
  }
}
