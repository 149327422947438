<script lang="ts" setup>
import { computed } from 'vue';
import { SIZES } from '@fsd/shared/constants';
import { AText, AIcon } from '@fsd/shared/ui';
import { AccordionSizes, BORDER_RADIUS, PADDING, SIZE_TITLE } from './config';

defineOptions({
  name: 'a-accordion',
});

const props = withDefaults(defineProps<{
  size?: AccordionSizes,
}>(), {
  size: SIZES.M,
});

const classes = computed(() => ({
  [BORDER_RADIUS[props.size]]: true,
  [PADDING[props.size]]: true,
}));

const sizeTitle = computed(() => SIZE_TITLE[props.size]);
</script>

<template>
  <div
    class="group border border-solid !border-stroke-primary bg-background-primary-elevated
    transition-colors duration-150 focus-within:bg-stroke-primary focus-within:ring-4
    focus-within:ring-transparent-active hover:border-stroke-primary hover:bg-stroke-primary"
    :class="classes"
    tabindex="-1"
  >
    <details class="peer cursor-pointer">
      <summary class="flex items-center justify-between gap-2 focus-within:outline-none focus:outline-none">
        <div class="flex items-center justify-center">
          <div
            v-if="$slots.prepend"
            class="me-1.5"
          >
            <slot name="prepend" />
          </div>

          <a-text
            :class="['text-text-primary', sizeTitle]"
          >
            <slot name="title" />
          </a-text>
        </div>

        <div class="inline-flex size-10 shrink-0 items-center justify-center">
          <a-icon
            class="text-m transition-transform group-open:rotate-180 group-hover:scale-110"
            name="icon-arrow-down"
          />
        </div>
      </summary>
    </details>
    <div
      class="grid grid-rows-[0fr] transition-[grid-template-rows]
      duration-300 ease-in peer-open:grid-rows-[1fr]"
    >
      <div class="overflow-hidden">
        <div class="my-4 h-px bg-stroke-primary" />

        <slot />
      </div>
    </div>
  </div>
</template>
