import { SIZES } from './sizes';

export const DESIGN_TYPES = {
  FILL: 'fill',
  OUTLINE: 'outline',
  GHOST: 'ghost',
  LINK: 'link',
} as const;

export const COMPOSITION_TYPES = {
  TEXT: 'text',
  RIGHT_ICON: 'right-icon',
  LEFT_ICON: 'left-icon',
  ONLY_ICON: 'icon',
} as const;

export const SIZE_HEIGHT_CLASSES = {
  [SIZES.S]: 'h-6',
  [SIZES.M]: 'h-8',
  [SIZES.L]: 'h-10',
  [SIZES.XL]: 'h-12',
};

export const SIZE_WIDTH_CLASSES = {
  [SIZES.S]: 'w-6',
  [SIZES.M]: 'w-8',
  [SIZES.L]: 'w-10',
  [SIZES.XL]: 'w-12',
};

export const SIZE_GAP_CLASSES = {
  [SIZES.S]: 'gap-1',
  [SIZES.M]: 'gap-1',
  [SIZES.L]: 'gap-1',
  [SIZES.XL]: 'gap-2',
};

export const SIZE_PADDING_CLASSES = {
  [COMPOSITION_TYPES.TEXT]: {
    [SIZES.S]: 'px-2',
    [SIZES.M]: 'px-3',
    [SIZES.L]: 'px-4',
    [SIZES.XL]: 'px-6',
  },
  [COMPOSITION_TYPES.ONLY_ICON]: {
    [SIZES.S]: 'px-1',
    [SIZES.M]: 'px-2',
    [SIZES.L]: 'px-3',
    [SIZES.XL]: 'px-4',
  },
  [COMPOSITION_TYPES.RIGHT_ICON]: {
    [SIZES.S]: 'ps-2 pe-1.5',
    [SIZES.M]: 'ps-3 pe-2',
    [SIZES.L]: 'ps-4 pe-3',
    [SIZES.XL]: 'ps-6 pe-4',
  },
  [COMPOSITION_TYPES.LEFT_ICON]: {
    [SIZES.S]: 'pe-2 ps-1.5',
    [SIZES.M]: 'pe-3 ps-2',
    [SIZES.L]: 'pe-4 ps-3',
    [SIZES.XL]: 'pe-6 ps-4',
  },
};

export const SIZE_FONT_CLASSES = {
  [SIZES.S]: 'text-s',
  [SIZES.M]: 'text-s',
  [SIZES.L]: 'text-m',
  [SIZES.XL]: 'text-m',
};

export const SIZE_ICON_CLASSES = {
  [SIZES.S]: 'text-m',
  [SIZES.M]: 'text-m',
  [SIZES.L]: 'text-l',
  [SIZES.XL]: 'text-xl',
};

export const BORDER_RADIUS_CLASSES = {
  [SIZES.S]: '!rounded before:!rounded after:!rounded',
  [SIZES.M]: '!rounded before:!rounded after:!rounded',
  [SIZES.L]: '!rounded-md before:!rounded-md after:!rounded-md',
  [SIZES.XL]: '!rounded-lg before:!rounded-lg after:!rounded-lg',
};
