import { AppModuleNext } from '@amarkets/atlas/esm/lib/app-module-next';
import { STORE_MODULE_EMAIL } from './const';

const rootComponent = () => import(
  './components/module-root/module-root.vue'
);

const storeAsyncFactory = () => import(
  './store'
);

const props = {
  context: {
    type: String,
    default: '',
  },
};

export default {
  module: new AppModuleNext(rootComponent, {
    storeModuleName: STORE_MODULE_EMAIL,
    storeAsyncFactory,
    props,
  }),
};
