import { Action } from '@amarkets/atlas/esm/lib/action';
import { useUserStore } from '@fsd/entities/user-profile';
import {
  MUTATION_SET_IS_USER_PROFILE_LOADING,
  MUTATION_SET_USER_PROFILE,
} from '../types';

export class FetchUserProfile extends Action {
  _api;
  _errorHandler;

  constructor({ accountApi, errorHandler }) {
    super();
    this._api = accountApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    await FetchUserProfile._withLoading(async () => {
      try {
        const userStore = useUserStore();
        await userStore.fetchProfile();

        commit(MUTATION_SET_USER_PROFILE, userStore.profile);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchUserProfile');
      }
    }, commit, MUTATION_SET_IS_USER_PROFILE_LOADING);
  }
}
