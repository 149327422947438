import { VuexHelper } from '@amarkets/atlas/esm/lib/vuex-helper';
import { STORE_MODULE_GLOBAL } from '@/plugins/store/modules/global/types';
import { STORE_MODULE_AUTH } from '@/plugins/store/modules/auth/types';
import { STORE_MODULE_IDENTITY } from '@/plugins/store/modules/identity/types';
import { STORE_MODULE_PAYMENT_METHODS } from '@/plugins/store/modules/payment-methods/types';
import { STORE_MODULE_OPEN_ACCOUNT } from '@/plugins/store/modules/open-account/types';
import { STORE_MODULE_PREMIUM_ANALYTICS } from '@/plugins/store/modules/premium-analytics/types';
import { STORE_MODULE_DEPOSIT } from '@/plugins/store/modules/deposit/types';
import { STORE_MODULE_EXCHANGE } from '@/plugins/store/modules/exchange/types';

// modules
import { STORE_MODULE_EMAIL } from '@/modules/email/const';
import { STORE_MODULE_PHONE } from '@/modules/phone/const';
import { STORE_MODULE_PASSWORD } from '@/modules/password/const';
import { STORE_MODULE_ENABLE_2FA } from '@/modules/enable-second-factor/const';
import { STORE_MODULE_DISABLE_2FA } from '@/modules/disable-second-factor/const';

// global plugins
import createGlobalModule from '@/plugins/store/modules/global';
import createAuthModule from '@/plugins/store/modules/auth';
import createIdentityModule from '@/plugins/store/modules/identity';
import createPaymentMethodsModule from '@/plugins/store/modules/payment-methods';
import createOpenAccountModule from '@/plugins/store/modules/open-account';
import createPremiumAnalyticsModule from '@/plugins/store/modules/premium-analytics';
import createDepositModule from '@/plugins/store/modules/deposit';
import createExchangeModule from '@/plugins/store/modules/exchange';
// modules
import createEmailModule from '@/modules/email/store';
import createPhoneModule from '@/modules/phone/store';
import createPasswordModule from '@/modules/password/store';
import createEnableSecondFactorModule from '@/modules/enable-second-factor/store';
import createDisableSecondFactorModule from '@/modules/disable-second-factor/store';

export function registerStoreModules(context) {
  const storeFactories = {
    [STORE_MODULE_GLOBAL]: createGlobalModule,
    [STORE_MODULE_AUTH]: createAuthModule,
    [STORE_MODULE_EMAIL]: createEmailModule,
    [STORE_MODULE_PHONE]: createPhoneModule,
    [STORE_MODULE_PASSWORD]: createPasswordModule,
    [STORE_MODULE_IDENTITY]: createIdentityModule,
    [STORE_MODULE_ENABLE_2FA]: createEnableSecondFactorModule,
    [STORE_MODULE_DISABLE_2FA]: createDisableSecondFactorModule,
    [STORE_MODULE_PAYMENT_METHODS]: createPaymentMethodsModule,
    [STORE_MODULE_OPEN_ACCOUNT]: createOpenAccountModule,
    [STORE_MODULE_PREMIUM_ANALYTICS]: createPremiumAnalyticsModule,
    [STORE_MODULE_DEPOSIT]: createDepositModule,
    [STORE_MODULE_EXCHANGE]: createExchangeModule,
  };

  Object
    .entries(storeFactories)
    .forEach(([moduleName, createStoreModule]) => {
      VuexHelper.registerStoreModule({
        store: context.store,
        module: createStoreModule(context),
        moduleName,
      });
    });
}
