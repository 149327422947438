import { AxiosError, AxiosInstance } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

interface RefreshTokenParams {
  refreshAuthLogic?: (error: unknown) => Promise<unknown>;
  pauseInstanceWhileRefreshing?: boolean;
  onTokenExpired?: () => Promise<{ accessToken: string }>;
  onTokenFail?: () => void;
}

const createRefreshTokenAuth = (config: RefreshTokenParams) => async (failedRequest: AxiosError) => {
  const request = failedRequest;
  try {
    if (config.onTokenExpired) {
      const response = await config.onTokenExpired();

      if (request.response?.config?.headers) {
        request.response.config.headers.Authorization = `Bearer ${response?.accessToken}`;
      }

      return request;
    }
  } catch {
    if (config.onTokenFail) {
      config.onTokenFail();
    }

    return request;
  }

  return request;
};

export const useRefreshTokenInterceptor = (
  params: RefreshTokenParams,
  axiosInstance: AxiosInstance,
): void => {
  const refreshAuth = params?.refreshAuthLogic || createRefreshTokenAuth({
    onTokenExpired: params?.onTokenExpired, onTokenFail: params?.onTokenFail,
  });

  const options = {
    pauseInstanceWhileRefreshing: params?.pauseInstanceWhileRefreshing,
    statusCodes: [401],
  };

  createAuthRefreshInterceptor(axiosInstance, refreshAuth, options);
};
