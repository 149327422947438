import { useHttpClient } from '@fsd/shared/api/http';
import { PaymentMethodType } from './types';

const { get } = useHttpClient();

export const usePaymentMethodTypesApi = () => {
  const fetchPaymentMethodTypes = async () => {
    const data = await get<PaymentMethodType[]>('/v1/payment_methods/types');

    return data;
  };

  return {
    fetchPaymentMethodTypes,
  };
};
