import { SIZES } from '@fsd/shared/constants';

export type AccordionSizes = 's' | 'm' | 'l';

export interface Props {
  size?: AccordionSizes,
}

export const BORDER_RADIUS: Record<AccordionSizes, string> = {
  [SIZES.S]: 'rounded-xl',
  [SIZES.M]: 'rounded-2xl',
  [SIZES.L]: 'rounded-3xl',
};

export const PADDING: Record<AccordionSizes, string> = {
  [SIZES.S]: 'p-4',
  [SIZES.M]: 'p-6',
  [SIZES.L]: 'p-8',
};

export const SIZE_TITLE: Record<AccordionSizes, string> = {
  [SIZES.S]: '!text-m',
  [SIZES.M]: '!text-l',
  [SIZES.L]: '!text-2xl',
};
