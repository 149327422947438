import { useHttpClient } from '../../http/index';
import type { PostDocVerifierResponse, UpdateDocumentPayload } from './types';

const { post, patch } = useHttpClient();

export const useDocVerifierApi = () => {
  const createVerificationSession = async () => {
    const { data } = await post<PostDocVerifierResponse>(
      '/v1/doc-verifier/sdk/verification_sessions',
      null,
    );

    return { data };
  };

  const startVerificationSession = async (token: string) => post(
    '/v1/doc-verifier/sdk/verification_sessions/start',
    null,
    {
      headers: {
        'X-Verification-Session': token,
      },
    },
  );

  const finishVerificationSession = async (token: string) => post(
    '/v1/doc-verifier/sdk/verification_sessions/finish',
    null,
    {
      headers: {
        'X-Verification-Session': token,
      },
    },
  );

  const uploadDocumentForVerification = async (token: string, formData: FormData) => post(
    '/v1/doc-verifier/sdk/documents',
    formData,
    {
      headers: {
        'X-Verification-Session': token,
      },
    },
  );

  const updateDocumentForVerification = async (token: string, { documentUid, ...rest }: UpdateDocumentPayload) => patch(
    `/v1/doc-verifier/sdk/documents/${documentUid}`,
    {
      data: {
        id: documentUid,
        type: 'documents',
        attributes: rest,
      },
    },
    {
      headers: {
        'X-Verification-Session': token,
      },
    },
  );

  return {
    createVerificationSession,
    startVerificationSession,
    uploadDocumentForVerification,
    finishVerificationSession,
    updateDocumentForVerification,
  };
};
