<script setup lang="ts">
import { computed } from 'vue';
import { SIZES } from '@fsd/shared/constants';
import { SIZE_MAP, THEMES, type SpinnerSizes } from '../types/const';

const props = withDefaults(defineProps<{
  size?: SpinnerSizes,
  theme?: typeof THEMES[keyof typeof THEMES],
}>(), {
  size: SIZES.M,
  theme: THEMES.LIGHT,
});

const interpretedSize = computed(() => SIZE_MAP[props.size]);

const spinnerSize = computed(() => `--spinner-size: ${interpretedSize.value}px`);

const darkDotClasses = computed(() => ({
  'bg-icons-primary': props.theme === THEMES.DARK,
  'bg-icons-inverted': props.theme === THEMES.LIGHT,
}));
</script>

<template>
  <div
    class="loader relative"
    :style="spinnerSize"
  >
    <span
      class="loader__dot loader__dot--1 absolute z-[3] block rounded-full
      bg-icons-tertiary bg-gradient-to-t from-transparent-gray-10 to-transparent-gray-10"
    />
    <span
      class="loader__dot loader__dot--2 absolute z-[4] block rounded-full
      bg-gradient-to-t from-transparent-gray-10 to-transparent-gray-10"
      :class="darkDotClasses"
    />
    <span
      class="loader__dot loader__dot--3 absolute z-[2] block rounded-full
      bg-gradient-to-t from-transparent-gray-10 to-transparent-gray-10"
      :class="darkDotClasses"
    />
    <span
      class="loader__dot loader__dot--4 absolute z-[1] block rounded-full
      bg-icons-tertiary bg-gradient-to-t from-transparent-gray-10 to-transparent-gray-10"
    />
  </div>
</template>

<style lang="scss" scoped>
  @import 'a-spinner-dot';
</style>
