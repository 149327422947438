import { createPinia } from 'pinia';
import { JwtService } from '@amarkets/atlas/esm/lib/jwt-service';
import { HttpClient } from '@amarkets/atlas/esm/lib/http-client';
import { AuthApi } from '@amarkets/atlas/esm/lib/auth-api';
import { SsoClient } from '@amarkets/sso-client';
import { HttpErrorParser } from '@amarkets/atlas/esm/lib/http-error-parser';
import { removeQueryFromUrl } from '@amarkets/atlas/esm/lib/remove-query-from-url';
import { useI18n } from '@amarkets/core-vue';
import en from '@amarkets-web/front-office-locales/translations/en.json';
import { I18N_ERROR_KEYS } from '@/constants';
import { useStore } from '@/plugins/store';
import { useRouter } from '@/plugins/router';
import { useVuetify } from '@/plugins/vuetify';
import { createSentry } from '@/plugins/sentry';
import { createUiNotifier } from '@/plugins/vue-notification';
import { createCentrifuge } from '@/plugins/centrifuge';
import { getAllQuery, getCurrentUrlWithoutCode } from '@/lib/utils';
import { isBlockingUser } from '@/plugins/router/guards';
import { createApiServices } from './create-api-services';
import { createHttpClient } from './create-http-client';
import { createConfig } from './create-config';
import { setToLoading, setToError } from '@/bootstrap/init-state-machine';

export async function createCtx(authSSODomain = '') {
  const config = createConfig({ authSSODomain });

  const ssoClientConfig = {
    origin: getCurrentUrlWithoutCode(window.location.href),
    clientId: config.SSO.CLIENT_ID,
    authServerUri: config.SSO.AUTH_SERVER_URI,
  };

  // api service for other services usage without access / refresh updating
  const authApi = new AuthApi(new HttpClient(), config.API.AUTH);
  const ssoClient = new SsoClient(window, authApi, ssoClientConfig);

  const query = getAllQuery(window.location.href);
  const newUrl = removeQueryFromUrl(window.location.href, ['lang']);
  window.history.replaceState(null, null, newUrl);

  const i18n = await useI18n({
    messages: { en },
  });
  const jwtService = new JwtService(authApi);
  const httpClient = createHttpClient({ config, jwtService, ssoClient, i18n });
  const apiServices = createApiServices({ httpClient, config });
  const store = useStore();
  const pinia = createPinia();
  const router = useRouter({ config });
  router.beforeEach(isBlockingUser(store));
  const vuetify = useVuetify({ i18n });
  const sentry = createSentry({ config });
  const uiNotifier = createUiNotifier();
  const centrifuge = createCentrifuge({ config });

  const httpErrorParser = new HttpErrorParser({
    defaultMsg: I18N_ERROR_KEYS.DEFAULT,
    msgByStatusCode: I18N_ERROR_KEYS.BY_STATUS_CODE,
    i18n,
  });

  return {
    apiServices,
    httpErrorParser,
    uiNotifier,
    sentry,
    store,
    pinia,
    router,
    vuetify,
    i18n,
    ssoClient,
    jwtService,
    centrifuge,
    globalLoader: {
      setToLoading,
      setToError,
    },
    config,
    isWebview: query?.isWebview || localStorage.getItem('isWebview') === 'true',
  };
}
