import { getAllQuery } from '@/lib/utils/index';
import FacadeAuth from '@/plugins/store/facades/facade-auth';
import { LOCALES } from '@/constants';

export const updateLocale = async ({ store }) => {
  const query = getAllQuery(window.location.search);

  if (
    !query.userLocale
    || (query.userLocale && !Object.values(LOCALES).includes(query.userLocale))
  ) {
    return;
  }

  const facadeAuth = new FacadeAuth(store);
  await facadeAuth.updateLocale({ locale: query.userLocale });
};
