import { AxiosError } from 'axios';
import { parseValidationError, parseBusinessLogicError } from './lib';

export const MAX_ERROR_LENGTH = 100;

export const DEFAULT_MSG = 'Something went wrong... Please try again later.';

export const STATUS_CODES = {
  VALIDATION: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  GONE: 410,
  BUSINESS_LOGIC: 422,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
} as const;

export const I18N_ERROR_KEYS_BY_STATUS_CODES = {
  BY_STATUS_CODE: {
    401: 'common.error_status.401',
    403: 'common.error_status.403',
    404: 'common.error_status.404',
    410: 'common.error_status.410',
    429: 'common.error_status.429',
    500: 'common.error_status.500',
    502: 'common.error_status.502',
  },
  DEFAULT: 'common.error.default',
};

export const DEFAULT_MSG_BY_STATUS_CODE: Record<number, string> = {
  [STATUS_CODES.UNAUTHORIZED]: 'You are not an authorized user. Please go through the authorization process.',
  [STATUS_CODES.FORBIDDEN]: 'Permission denied. You are not authorized to view this resource.',
  [STATUS_CODES.NOT_FOUND]: 'Resource not found or never existed.',
  [STATUS_CODES.GONE]: 'The resource no longer exists. Please contact our customer service.',
  [STATUS_CODES.TOO_MANY_REQUESTS]: 'Too many wrong login attempts. Please go through the captcha.',
  [STATUS_CODES.INTERNAL_SERVER_ERROR]: 'Something went wrong... Please try again later.',
  [STATUS_CODES.BAD_GATEWAY]: 'Bad gateway. Please try again later.',
};

export const DEFAULT_PARSERS_BY_STATUS_CODE: Record<
  number,
  (error: AxiosError) => unknown
> = {
  [STATUS_CODES.VALIDATION]: parseValidationError,
  [STATUS_CODES.BUSINESS_LOGIC]: parseBusinessLogicError,
  [STATUS_CODES.FORBIDDEN]: parseBusinessLogicError,
};
