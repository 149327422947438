import packageInfo from '../../../package.json';
import { LOCALES } from '@/constants';

export const createConfig = ({ authSSODomain } = {}) => {
  const mainApiUrl = import.meta.env.VITE_API_URL_EXTERNAL;

  return {
    APP: {
      APP_RELEASE: `${packageInfo.name}@${packageInfo.version}`,
      DEFAULT_LOCALE: LOCALES.EN,
      BASE_URL: import.meta.env.BASE_URL,
      ENV: import.meta.env.NODE_ENV,
      ROUTER_BASE: import.meta.env.VITE_ROUTER_BASE,
    },
    SENTRY: {
      ENV: import.meta.env.VITE_SENTRY_ENV,
      DSN: import.meta.env.VITE_SENTRY_DSN,
    },
    API: {
      EXTERNAL: mainApiUrl,
      AUTH: authSSODomain?.API
        ?? import.meta.env.VITE_API_URL_AUTH,
      AGORA: `${mainApiUrl}/v1/agora`, // TODO: remove after migration into fsd
      DEPOSIT: `${mainApiUrl}/v2/pc`, // TODO: remove after migration into fsd
      DOC_VERIFIER: import.meta.env.VITE_API_URL_DOC_VERIFIER,
    },
    WEBSOCKETS: import.meta.env.VITE_WEBSOCKET_URL,
    SSO: {
      AUTH_SERVER_URI: authSSODomain?.SSO
        ?? import.meta.env.VITE_AUTH_SERVER_URI,
      CLIENT_ID: import.meta.env.VITE_CLIENT_ID,
    },
  };
};
