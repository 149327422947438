import { I18n } from 'vue-i18n';

declare global {
  interface Window {
    i18n: I18n
  }
}

export const useLocale = () => {
  const i18n = window.i18n ? window.i18n : null;

  const currentLocale = <string>(() => {
    const locale = i18n?.global?.locale;
    if (typeof locale === 'object' && 'value' in locale) {
      return locale.value;
    }
    return locale;
  })();

  return {
    $t: i18n?.global?.t,
    currentLocale,
  };
};
