export const WILDCARD_DOMAIN_LIST = {
  stage: {
    DEFAULT: 'https://auth.stage.amarkets.dev',
    RU: '*.auth-app.info',
    DE: 'https://login1.auth-app.info',
  },
  prod: {
    DEFAULT: '*.auth-app.site',
    RU: 'https://login1.auth-app.net',
    IR: 'https://login1.auth-app.net',
    US: 'https://login1.auth-app.net',
    UA: '*.auth-app.site',
    ME: '*.auth-app.site',
    TR: '*.auth-app.site',
    VN: '*.auth-app.site',
    MY: '*.auth-app.site',
  },
};

export const CLIENT_IP_URL = 'https://api.du3b.com/v1/client_ip';
