import { RouteRecordRaw } from 'vue-router';
import { FINANCE_ROUTES } from '@fsd/shared/constants/routes';

export const TransferRoutes: RouteRecordRaw[] = [
  {
    name: FINANCE_ROUTES.TRANSFER.NAME,
    path: FINANCE_ROUTES.TRANSFER.PATH,
    component: () => import('./page-transfer.vue'),
    meta: {
      title: 'menu.link.internalTransfers',
    },
  },
];
