export default {
  OPEN_ACCOUNT: {
    PATH: '/settings/open-account',
    NAME: 'open-account',
  },
  OPEN_ACCOUNT_EDIT: {
    PATH: '/settings/open-account/edit',
    NAME: 'open-account-edit',
  },
  OPEN_ACCOUNT_SUCCESS: {
    PATH: '/settings/open-account/success',
    NAME: 'open-account-success',
  },
  OPEN_ACCOUNT_DEMO: {
    PATH: '/settings/open-account-demo',
    NAME: 'open-account-demo',
  },
  OPEN_ACCOUNT_DEMO_SUCCESS: {
    PATH: '/settings/open-account-demo/success',
    NAME: 'open-account-demo-success',
  },
  LEVERAGE_CHANGE: {
    PATH: '/settings/leverage-change',
    NAME: 'leverage-change',
  },
};
