export default {
  digestsPreview: [],
  dailyEvents: [],
  ideas: [],
  digestItems: [],
  eventItems: [],
  ideaItems: [],
  tags: [],
  activeTag: null,
};
