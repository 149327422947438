import { useHttpClient } from '../../http/index';
import {
  GetClientsParams,
  GetClientsResponse,
  GetRequestStatusResponse,
  PostBecomePartnerFriend, PostReferralCode,
} from './types';

const { get, post } = useHttpClient();

export const usePartnerApi = () => {
  const getClients = async (params?: GetClientsParams) => {
    const { data, meta } = await get<GetClientsResponse>(
      '/v1/partner_friend/clients', {
        params,
      });

    return {
      data,
      meta,
    };
  };

  const postReferralCode = async (code: string) => {
    const { data } = await post<PostReferralCode>(
      '/v1/partners/create_change_request',
      {
        data: {
          type: 'change_request',
          attributes: { code },
        },
      },
    );

    return data;
  };

  const getReferralCodeStatus = async () => {
    const { data } = await get<GetRequestStatusResponse>(
      '/v1/partners/change_requests',
    );

    return {
      data,
    };
  };

  const cancelReferralCode = async () => {
    const { data } = await post<GetRequestStatusResponse>(
      '/v1/partners/cancel_request',
    );

    return {
      data,
    };
  };

  const becomePartnerFriend = async () => {
    const { promoCode } = await post<PostBecomePartnerFriend>(
      '/v1/partner_friend',
      {
        data: {
          type: 'partner_registrations',
        },
      },
    );

    return {
      promoCode,
    };
  };

  return {
    getClients,
    postReferralCode,
    getReferralCodeStatus,
    becomePartnerFriend,
    cancelReferralCode,
  };
};
