import {
  MUTATION_SET_IS_LOADING,
  MUTATION_SET_IS_LOADING_CREATE_PAYMENT_REQUEST,
  MUTATION_SET_ACCOUNTS,
  MUTATION_SET_SELECTED_ACCOUNT,
  MUTATION_SET_PAYMENT_METHODS,
  MUTATION_SET_SELECTED_PAYMENT_METHOD,
  MUTATION_SET_SERVER_ERROR,
} from './types';

export default {
  [MUTATION_SET_IS_LOADING](state, status) {
    state.isLoading = status;
  },
  [MUTATION_SET_IS_LOADING_CREATE_PAYMENT_REQUEST](state, status) {
    state.isLoadingCreatePaymentRequest = status;
  },
  [MUTATION_SET_ACCOUNTS](state, accounts) {
    state.accounts = accounts;
  },
  [MUTATION_SET_SELECTED_ACCOUNT](state, account) {
    state.selectedAccount = account;
  },
  [MUTATION_SET_PAYMENT_METHODS](state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  [MUTATION_SET_SELECTED_PAYMENT_METHOD](state, paymentMethod) {
    state.selectedPaymentMethod = paymentMethod;
  },
  [MUTATION_SET_SERVER_ERROR](state, error) {
    state.serverError = error;
  },
};
