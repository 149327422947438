import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { localStorageLib } from '@fsd/shared/lib';
import { API_URL_EXTERNAL } from '../../consts/api-names';
import type { HttpClientConfig } from '../types';
import {
  baseReqInterceptors, baseRespInterceptors, useInterceptors, useRefreshTokenInterceptor,
} from './index';

const { getItem } = localStorageLib.useLocalStorage();

let axiosInstance: AxiosInstance | null = null;

export const getAxiosInstance = (customConfig?: HttpClientConfig, useSingleton = true): AxiosInstance => {
  if (useSingleton && axiosInstance && !customConfig) {
    return axiosInstance;
  }

  const baseConfig: AxiosRequestConfig = {
    baseURL: API_URL_EXTERNAL,
    timeout: 10000,
    ...customConfig,
  };

  const instance = axios.create(baseConfig);

  instance.interceptors.request.use(
    (requestConfig) => {
      const newConfig = requestConfig;
      const token = getItem('accessToken');
      newConfig.headers = newConfig.headers ?? {};
      newConfig.headers.Authorization = `Bearer ${token}`;

      return newConfig;
    },
  );

  instance.interceptors.request.use(
    useInterceptors([...baseReqInterceptors.success]),
    useInterceptors([...baseReqInterceptors.error]),
  );

  instance.interceptors.response.use(
    useInterceptors([...baseRespInterceptors.success]),
    useInterceptors([...baseRespInterceptors.error]),
  );

  if (customConfig?.requestInterceptors) {
    customConfig?.requestInterceptors.forEach((interceptor) => {
      instance?.interceptors.request.use(interceptor.onFulfilled, interceptor.onRejected);
    });
  }

  if (customConfig?.responseInterceptors) {
    customConfig?.responseInterceptors.forEach((interceptor) => {
      instance?.interceptors.response.use(interceptor.onFulfilled, interceptor.onRejected);
    });
  }

  useRefreshTokenInterceptor(
    customConfig as Pick<HttpClientConfig, 'onTokenExpired' | 'onTokenFail' | 'pauseInstanceWhileRefreshing'>,
    instance,
  );

  if (useSingleton) {
    axiosInstance = instance;
  }

  return instance;
};
