import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';
import { DOCUMENT_STATUSES, UserIdentify, VerificationType } from '../../types';

type UserIdentifyStoreState = Pick<UserIdentify, 'phoneNumberVerified' | 'emailVerified' | 'documents'>;

export const useUserIdentifyStore = defineStore('user-identify', () => {
  const userIdentity = reactive<UserIdentifyStoreState>({
    phoneNumberVerified: false,
    emailVerified: false,
    documents: [],
  });

  const updateState = (data: UserIdentify) => {
    Object.assign(userIdentity, data);
  };

  const isDocumentVerified = computed(
    () => userIdentity.documents?.length > 0 && userIdentity.documents[0]?.status === DOCUMENT_STATUSES.ACCEPTED,
  );

  const isBasicVerified = computed(
    () => userIdentity.phoneNumberVerified && userIdentity.emailVerified,
  );

  const isFullyVerified = computed(
    () => isBasicVerified.value && isDocumentVerified.value,
  );

  const isUserVerified = (
    type: VerificationType,
  ) => (type === 'simple' ? isBasicVerified.value : isFullyVerified.value);

  return {
    userIdentity,
    isUserVerified,
    updateState,
  };
});
