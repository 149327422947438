import { useHttpClient } from '../../http/index';
import { GetFCouponsParticipantResponse } from './types';

const { get, post } = useHttpClient();

export const useFCouponsApi = () => {
  const getFCouponsParticipant = async (uid: string) => {
    const { data } = await get<GetFCouponsParticipantResponse>(
      `/v1/fcoupons/competition/${uid}/participant`,
      {
        errorHandlerOptions: {
          skipErrorByOneOfCodes: [404],
        },
      },
    );

    return {
      data,
    };
  };

  const postFCouponAcceptTerms = async () => {
    const data = await post('/v1/fcoupons/competition/accept_terms');

    return {
      data,
    };
  };

  return {
    getFCouponsParticipant,
    postFCouponAcceptTerms,
  };
};
