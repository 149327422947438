import { useHttpClient } from '../../http/index';
import type { AccountsResponse, CreateTransferRequest, CreateTransferPayload } from './types';

const { get, post } = useHttpClient();

export const useTransfersApi = () => {
  const fetchAccounts = async () => {
    const { data } = await get<AccountsResponse>('/v1/office/transfers/accounts');

    return data;
  };

  const createTransfer = async ({
    senderId,
    senderType,
    recipientId,
    recipientType,
    amount,
  }: CreateTransferPayload) => {
    const payload = {
      data: {
        type: 'create_transfer',
        attributes: {
          sender: {
            uuid: senderId,
            type: senderType,
          },
          recipient: {
            uuid: recipientId,
            type: recipientType,
          },
          amount,
        },
      } as CreateTransferRequest,
    };
    const { data } = await post<AccountsResponse>('/v1/office/transfers', payload, {
      errorHandlerOptions: {
        skipErrorByOneOfCodes: [422],
      },
    });

    return data;
  };

  return {
    fetchAccounts,
    createTransfer,
  };
};
