import { LOCALES } from '@/constants';

const EXCLUDED = [LOCALES.HI, LOCALES.NG, LOCALES.ES];

export const getSurveyAliasByLocale = (locale) => {
  const isNotExist = !Object.values(LOCALES).includes(locale);
  const isExcluded = EXCLUDED.includes(locale);

  return `userRiskProfile_${isNotExist || isExcluded ? 'en' : locale}`;
};
