import { Action } from '@amarkets/atlas/esm/lib/action';
import {
  MUTATION_SET_IS_LOADING_CREATE_PAYMENT_REQUEST,
  MUTATION_SET_SERVER_ERROR,
} from '../types';

export class CreatePaymentRequest extends Action {
  _api;
  _errorHandler;

  constructor({ depositApi, errorHandler }) {
    super();
    this._api = depositApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, payload) {
    let hasServerError = false;
    let resp = null;

    commit(MUTATION_SET_SERVER_ERROR, '');

    await CreatePaymentRequest._withLoading(async () => {
      try {
        resp = await this._api.createPaymentRequest(payload);
      } catch (e) {
        if (e?.response?.status === 422) {
          hasServerError = true;
          commit(
            MUTATION_SET_SERVER_ERROR,
            e?.response?.data?.data?.['data.attributes.charge.amount']?.[0] || '',
          );
        } else {
          this._errorHandler.handle(e, 'while execute CreatePaymentRequest');
        }
      }
    }, commit, MUTATION_SET_IS_LOADING_CREATE_PAYMENT_REQUEST);

    return hasServerError ? false : resp;
  }
}
