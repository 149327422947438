<template>
  <div class="layout-init__content flex items-center justify-center flex-col">
    <p class="text-xl text-text-headline mb-2">
      An unexpected error occurred
    </p>
    <p class="mb-6 text-l text-text-secondary">
      Click the button below to refresh the page
    </p>

    <div>
      <a-button
        type="fill"
        size="xl"
        @click="$router.go(0)"
      >
        Refresh
      </a-button>
    </div>
  </div>
</template>

<script>
import { AButton } from '@fsd/shared/ui';

export default {
  name: 'layout-init-error',
  components: { AButton },
};
</script>
