export default {
  DEMO: 'Demo',
  ISLAMIC: 'Islamic',
  STANDARD: 'Standard',
  ECN: 'ECN',
  PREMIUM: 'Premium',
  STRATEGY: 'Strategy',
  FIXED: 'Fixed',
  CRYPTO: 'Crypto',
  ZERO: 'Zero',
  MASTER: 'Master', // in figma it calls 'payment account'
  MASTER_DISPLAY_NAME: 'Copy Trading',
  INVEST: 'Invest',
  XXX: 'XXX',
  BONUS: 'Bonus',
  COPY_TRADING: 'Copy Trading',
};
