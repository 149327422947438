import { AxiosRequestConfig, AxiosError } from 'axios';
import { objectLib } from '@fsd/shared/lib';
import { useLocale } from '@fsd/shared/locale';

const decamelizeRequestConfig = (reqConfig: AxiosRequestConfig): AxiosRequestConfig => {
  const isFormData = reqConfig?.data instanceof FormData;

  return {
    ...reqConfig,
    params: reqConfig.params ? objectLib.decamelizeKeys(reqConfig.params, { deep: true }) : reqConfig.params,
    data: (isFormData || !reqConfig.data) ? reqConfig.data : objectLib.decamelizeKeys(reqConfig.data, { deep: true }),
  };
};

const rejectFinalErrorData = (err: AxiosError): Promise<AxiosError> => Promise.reject(err);

const setAcceptLanguage = (reqConfig: AxiosRequestConfig) => {
  const { currentLocale } = useLocale();
  const { headers } = reqConfig;

  headers!['Accept-Language'] = currentLocale;

  return reqConfig;
};

export const baseReqInterceptors = {
  success: [decamelizeRequestConfig, setAcceptLanguage],
  error: [rejectFinalErrorData],
};
