export const useLocalStorage = () => {
  const getItem = (key: string) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  };

  return {
    getItem,
  };
};
