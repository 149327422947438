export const useThrottle = (func, delay) => {
  let timeout = null;
  let lastResult = null;

  return async (...args) => {
    if (timeout) {
      return lastResult || Promise.resolve([]);
    }

    timeout = setTimeout(() => {
      timeout = null;
      lastResult = null;
    }, delay);

    try {
      lastResult = await func(...args);
      return lastResult;
    } catch (error) {
      console.error('Failed:', error);
      throw error;
    }
  };
};
