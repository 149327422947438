import { useHttpClient } from '../../http/index';
import { CopyTradingAccounts, CreateCopyTradingAccount, NewStrategy } from './types';

const { get, post } = useHttpClient();

export const useCopyTradingApi = () => {
  const getCopyTradingAccounts = async () => {
    const data = await get<CopyTradingAccounts>('/v1/office/copy_trading');
    return data;
  };

  const createPaymentAccount = async () => {
    const data = await post<CreateCopyTradingAccount>('/v1/office/copy_trading/create_payment_account');
    return data;
  };

  const getCopyTradingPlatformUrl = async () => {
    const data = await get<{ platformUrl: string }>('v1/office/copy_trading/platform_url');
    return data;
  };

  const createStrategy = async (strategy: NewStrategy) => {
    const data = await post('v1/office/copy_trading/create_master_account', strategy);

    return data;
  };

  return {
    getCopyTradingAccounts,
    createPaymentAccount,
    getCopyTradingPlatformUrl,
    createStrategy,
  };
};
