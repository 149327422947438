import { RouteRecordRaw } from 'vue-router';
import { ROUTES, SETTINGS_ROUTES } from '@/constants';

export const inviteFriendRoutes: RouteRecordRaw[] = [
  {
    name: ROUTES.INVITE_FRIEND.NAME,
    path: ROUTES.INVITE_FRIEND.PATH,
    component: () => import('@fsd/pages/invite-friend/index.vue'),
    children: [
      {
        name: ROUTES.INVITE_FRIEND_REFERRER.NAME,
        path: ROUTES.INVITE_FRIEND_REFERRER.PATH,
        component: () => import('@fsd/pages/invite-friend/referrer/index.vue'),
      },
      {
        name: ROUTES.INVITE_FRIEND_REFERRAL.NAME,
        path: ROUTES.INVITE_FRIEND_REFERRAL.PATH,
        component: () => import('@fsd/pages/invite-friend/referral/index.vue'),
      },
      {
        name: ROUTES.INVITE_FRIEND_NOT_ALLOWED.NAME,
        path: ROUTES.INVITE_FRIEND_NOT_ALLOWED.PATH,
        component: () => import('@fsd/pages/invite-friend/participation-restricted/index.vue'),
      },
    ],
    meta: { title: 'menu.item.inviteAFriend' },
  },
  {
    name: 'ui-kit',
    path: '/ui-kit',
    component: () => import('@fsd/pages/ui-kit/index.vue'),
  },
  {
    name: 'ui-kit-banners',
    path: '/ui-kit/banners',
    component: () => import('@fsd/pages/ui-kit/index-banners.vue'),
  },
];

export const docVerifierRoutes: RouteRecordRaw[] = [
  {
    name: ROUTES.DOC_VERIFIER.NAME,
    path: ROUTES.DOC_VERIFIER.PATH,
    component: () => import('@fsd/pages/doc-verifier/index.vue'),
  },
];

export const leverageChangeRoutes: RouteRecordRaw[] = [
  {
    name: SETTINGS_ROUTES.LEVERAGE_CHANGE.NAME,
    path: SETTINGS_ROUTES.LEVERAGE_CHANGE.PATH,
    component: () => import('@fsd/pages/leverage-change/index.vue'),
    meta: { title: 'menu.link.changeYourLeverage' },
  },
];

export { VpnRoutes } from './vpn/routes';
export { FCouponsRoutes } from './fcoupons/routes';
export { FinanceRoutes } from './finance/routes';
export { TradingPlanRoutes } from './trading-plan/routes';
export { ExpertsRoutes } from './experts/routes';
export { MyPartnerRoutes } from './my-partner/routes';
export { ChangeAccountType } from './change-account-type/routes';
export { CopyTradingRoutes } from './copy-trading/routes';
export { CashbackRoutes } from './cashback/routes';
export { VpsRoute } from './vps/routes';
export { TransferRoutes } from './transfer/routes';
export { OperationsHistoryRoutes } from './operations-history/routes';
