import { Currency } from '@amarkets/atlas/esm/lib/currency';

export class AgoraUserProfile {
  constructor({
    id,
    uid,
    login,
    leverage,
    credit,
    platform,
    equity,
    currency,
    dailyPnl,
    margin: {
      value,
      free,
      progress,
    },
  }) {
    Object.assign(this, {
      id,
      uid,
      login,
      leverage,
      currency,
      bonusAmount: credit,
      balance: AgoraUserProfile._getNumberWithCurrency(equity, currency),
      margin: AgoraUserProfile._getNumberWithCurrency(value, currency),
      dailyPnl: AgoraUserProfile._getNumberWithCurrency(dailyPnl),
      freeMargin: AgoraUserProfile._getNumberWithCurrency(free, currency),
      allMarginProgress: progress,
      platform,
    });
  }

  static _getNumberWithCurrency(number, currency = 'USD') {
    return new Currency({
      value: number,
      symbol: currency,
      precision: 2,
    }).currency.format();
  }
}
