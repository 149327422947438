import { RouteRecordRaw } from 'vue-router';
import { FINANCE_ROUTES } from '@fsd/shared/constants/routes';

export const OperationsHistoryRoutes: RouteRecordRaw[] = [
  {
    path: FINANCE_ROUTES.OPERATIONS_HISTORY.PATH,
    component: () => import('./index.vue'),
    children: [
      {
        name: FINANCE_ROUTES.OPERATIONS_HISTORY.NAME,
        path: FINANCE_ROUTES.OPERATIONS_HISTORY.PATH,
        component: () => import('./operations-history-items.vue'),
        meta: {
          title: 'operationsHistory.title',
        },
      },
      {
        name: FINANCE_ROUTES.OPERATIONS_HISTORY_ITEM.NAME,
        path: FINANCE_ROUTES.OPERATIONS_HISTORY_ITEM.PATH,
        component: () => import('./operations-history-item.vue'),
        props: true,
        meta: {
          title: 'operationsHistory.titleInfo',
        },
      },
    ],

  },
];
