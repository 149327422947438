<template>
  <app-spinner-dot
    class="layout-init__spinner"
    size="m"
  />
</template>

<script>
import { AppSpinnerDot } from '@amarkets/ui-kit-next';

export default {
  name: 'layout-init-loading',
  components: {
    AppSpinnerDot,
  },
};
</script>
