import { AppModuleNext } from '@amarkets/atlas/esm/lib/app-module-next';
import { CONTEXT } from '@/constants';
import { STORE_MODULE_PHONE } from './const';

const rootComponent = () => import(
  './components/module-root/module-root.vue'
);

const storeAsyncFactory = () => import(
  './store'
);

const props = {
  context: {
    type: String,
    default: CONTEXT.PROFILE,
  },
};

const propsProviderFactory = (module) => ({
  getContext: () => module.context,
});

export default {
  module: new AppModuleNext(rootComponent, {
    storeModuleName: STORE_MODULE_PHONE,
    storeAsyncFactory,
    props,
    propsProviderFactory,
  }),
};
