import { ref, Ref } from 'vue';

export function useLoading() {
  const isLoading: Ref<boolean> = ref(false);

  const withLoading = async <T>(fn : () => Promise<T>) => {
    isLoading.value = true;
    try {
      const result = await fn();
      return result;
    } finally {
      isLoading.value = false;
    }
  };

  return { isLoading, withLoading };
}
