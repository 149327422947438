<script setup lang="ts">
import { ICONS } from '../constants';

defineOptions({
  name: 'a-icon',
});

const props = defineProps<{
  name: typeof ICONS[keyof typeof ICONS],
}>();
</script>

<template>
  <i
    :class="props.name"
  />
</template>
