<script setup lang="ts">
import { computed, ComputedRef } from 'vue';
import { ATextProps, SIZE_CLASSES, TAGS, WEIGHT_CLASSES, WEIGHTS, SIZES } from '../const';

const props = withDefaults(defineProps<ATextProps>(), {
  size: SIZES.M,
  weight: WEIGHTS.MEDIUM,
});

const classes: ComputedRef<string[]> = computed(() => ([
  SIZE_CLASSES[props.size],
  WEIGHT_CLASSES[props.weight],
]));

const tag = computed((): typeof TAGS[keyof typeof TAGS] => TAGS[props.size]);
</script>

<template>
  <component
    :is="tag"
    :class="classes"
  >
    <slot />
  </component>
</template>
