import { useHttpClient } from '../../http/index';
import { getCashbackResponse, postCashbackRequestResponse, getCashbackRequestsResponse } from './types';

const { get, post } = useHttpClient();

export const useCashbackApi = () => {
  const getCashback = async () => {
    const { contracts } = await get<getCashbackResponse>('/v1/office/users/cashbacks');
    return contracts;
  };

  const postCashbackRequest = async () => {
    const { request } = await post<postCashbackRequestResponse>('/v1/office/loyalty_requests');
    return request;
  };

  const getCashbackRequests = async () => {
    const { requests } = await get<getCashbackRequestsResponse>('/v1/office/loyalty_requests');
    return requests;
  };

  return {
    getCashback,
    postCashbackRequest,
    getCashbackRequests,
  };
};
