export default {
  PAYMENT_METHODS: {
    PATH: '/finance/payment-methods',
    NAME: 'payment-methods',
  },
  PAYMENT_METHOD_TYPES: {
    PATH: '/finance/payment-method-types',
    NAME: 'payment-method-types',
  },
  NEW_PAYMENT_METHOD: {
    PATH: '/finance/new-payment-method/:paymentType',
    NAME: 'add-payment-method',
  },
  EDIT_MODE_PAYMENT_METHOD: {
    PATH: ':paymentType/:uid/edit',
    NAME: 'edit-payment-method',
  },
  DEPOSIT: {
    PATH: '/finance/deposit',
    NAME: 'deposit',
  },
  DEPOSIT_VERIFICATION: {
    PATH: '/finance/deposit/verification',
    NAME: 'deposit-verification',
  },
  DEPOSIT_SUCCESS: {
    PATH: '/finance/deposit/success',
    NAME: 'deposit-success',
  },
  DEPOSIT_FAIL: {
    PATH: '/finance/deposit/fail',
    NAME: 'deposit-fail',
  },
  OPERATIONS_HISTORY: {
    PATH: '/finance/operations-history',
    NAME: 'finance-operations-history',
  },
};
