export const SIZES = {
  'XS': 'xs',
  'S': 's',
  'M': 'm',
  'L': 'l',
  'XL': 'xl',
  '2XL': '2xl',
  '3XL': '3xl',
  '4XL': '4xl',
  '5XL': '5xl',
} as const;

export const WEIGHTS = {
  REGULAR: 'regular',
  MEDIUM: 'medium',
  SEMIBOLD: 'semibold',
  BOLD: 'bold',
} as const;

export type SizesType = typeof SIZES[keyof typeof SIZES];
export type WeightsType = typeof WEIGHTS[keyof typeof WEIGHTS];

export interface ATextProps {
  size?: SizesType;
  weight?: WeightsType;
}

export const SIZE_CLASSES = {
  [SIZES.XS]: 'text-xs',
  [SIZES.S]: 'text-s',
  [SIZES.M]: 'text-m',
  [SIZES.L]: 'text-l',
  [SIZES.XL]: 'text-xl',
  [SIZES['2XL']]: 'text-2xl',
  [SIZES['3XL']]: 'text-3xl',
  [SIZES['4XL']]: 'text-4xl',
  [SIZES['5XL']]: 'text-5xl',
};

export const WEIGHT_CLASSES = {
  [WEIGHTS.REGULAR]: 'font-normal',
  [WEIGHTS.MEDIUM]: 'font-medium',
  [WEIGHTS.SEMIBOLD]: 'font-semibold',
  [WEIGHTS.BOLD]: 'font-bold',
};

export const TAGS = {
  [SIZES.XS]: 'p',
  [SIZES.S]: 'p',
  [SIZES.M]: 'p',
  [SIZES.L]: 'h6',
  [SIZES.XL]: 'h5',
  [SIZES['2XL']]: 'h4',
  [SIZES['3XL']]: 'h3',
  [SIZES['4XL']]: 'h2',
  [SIZES['5XL']]: 'h1',
} as const;
