import { Centrifuge } from 'centrifuge';
import { parseAccessToken } from '@amarkets/atlas/esm/lib/parse-access-token';

export const createCentrifuge = ({ config }) => {
  if (!config.WEBSOCKETS) {
    return false;
  }

  let instance = null;

  const init = ($jwtService) => {
    instance = new Centrifuge(config.WEBSOCKETS, {
      data: { token: $jwtService.accessToken },
      async getData() {
        let token = $jwtService.accessToken;
        let tokenData = null;

        try {
          tokenData = parseAccessToken(token);

          if (Date.now() / 1000 > tokenData.expired) {
            await $jwtService.updateToken();
            token = $jwtService.accessToken;
          }
        } catch (e) {
          await $jwtService.updateToken();
          token = $jwtService.accessToken;
        }

        if (!token) {
          throw new Centrifuge.UnauthorizedError();
        }

        return { token };
      },
    });
  };

  const subscribeToChannel = (nameChannel, callback) => {
    const subscription = instance.getSubscription(nameChannel);

    if (subscription) {
      return subscription;
    }

    const subscribe = instance.newSubscription(nameChannel);

    subscribe.subscribe();
    subscribe.on('publication', callback);

    return subscribe;
  };

  const unsubscribeFromChannel = (subscription) => {
    if (subscription) {
      subscription.unsubscribe();
    }
  };

  return {
    instance,
    init: ($jwtService) => {
      init($jwtService);
      instance.connect();
    },
    connect: () => instance.connect(),
    disconnect: () => instance.disconnect(),
    subscribe: subscribeToChannel,
    unsubscribe: unsubscribeFromChannel,
  };
};
