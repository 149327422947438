import { useHttpClient } from '../../http/index';
import type {
  ServerRequest,
  ServersResponse,
} from './types';

const { get, post, put, delete: remove } = useHttpClient();

export const useVpsApi = () => {
  const fetchServers = async () => {
    const { data } = await get<ServersResponse>('/v2/vps/servers');

    return data;
  };

  const createServer = async () => {
    const payload = {
      data: {
        type: 'servers',
        attributes: {
          rate: 'expert',
          term: 1,
        },
      } as ServerRequest,
    };

    const { data } = await post<ServersResponse>('/v2/vps/servers', payload);

    return data;
  };

  const enableProlongServer = async (serverUuid: string) => {
    const payload = {
      data: {
        type: 'servers',
        attributes: {
          term: 1,
        },
      } as ServerRequest,
    };
    const { data } = await post<{ data: { message: string } }>(`v2/vps/servers/${serverUuid}/prolong`, payload);

    return data;
  };

  const enableAutoProlong = async (serverUuid: string) => {
    const { data } = await put<{ data: { message: string } }>(`v2/vps/servers/${serverUuid}/autoprolong`);

    return data;
  };

  const removeAutoProlong = async (serverUuid: string) => {
    const { data } = await remove<{ data: { message: string } }>(`v2/vps/servers/${serverUuid}/autoprolong`);

    return data;
  };

  return {
    fetchServers,
    createServer,
    enableProlongServer,
    enableAutoProlong,
    removeAutoProlong,
  };
};
